import React from "react";
import ReactDOM from "react-dom/client";
// import App from "./App";
// import { BrowserRouter } from "react-router-dom";
// import { Provider } from "react-redux";
// import store from "./app/store";
import "./index.css";
import "intro.js/introjs.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <div class="container">
    <h1>We'll Be Back Soon</h1>
    <p>We're currently doing some maintenance. We'll be back online shortly!</p>

    <div class="available-time">
      Expected back by:<br />
      <span>April 10, 2025 – 4:00 AM (Central Time)</span>
    </div>

    <p class="support">
      Need help? Contact us at 
      <a href="mailto:support@fireduptovote.com">support@fireduptovote.com</a>
    </p>
  </div>
  
);
